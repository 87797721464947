'use client';

import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useUserStore } from '@/lib/store/user.store';
import { useNotifyStore } from '@/lib/store/notify.store';

import {
  Container,
  Box,
  SxProps,
  useMediaQuery,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import { IconsNames } from '@/shared/components/Icon/icons.types';

import Logo from '@/shared/components/LogoComponent';
import Button from '@/shared/components/ButtonComponent';
import Icon from '@/shared/components/Icon';
import GeneralModal from '@/shared/components/GeneralModal';

import { colors } from '@/lib/theme/palette';
import theme from '@/lib/theme/theme';

import Auth from '@/shared/features/Auth';

import AuthDataApi from '@/shared/features/Auth/api/auth.api';
import {
  AuthType,
  ConfirmForm,
  CreateNewPasswordForm,
  GeneralForm,
  onSubmitAuthFormType,
  ResesOtpCodeForm,
  ResetPasswordForm,
  SignInForm,
  SignUpForm,
} from '@/shared/features/Auth/types';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import httpClient from '@/lib/client/httpClient';
import { useRouter, usePathname } from 'next/navigation';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';
import SearchBar from '@/shared/components/SearchBar';

const buttonsStyles = {
  height: {
    xs: '36px',
    sm: '48px',
    xl: '60px',
  },
  fontSize: {
    md: '16px',
    xl: '20px',
  },
};

type HeaderButtonsProps = {
  text: string;
  icon: IconsNames;
  sx?: SxProps;
  onClick?: () => void;
};
const HeaderButtons = (props: HeaderButtonsProps) => {
  const { sx, text, icon, onClick } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    ...buttonsStyles,
    ...sx,
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      component="span"
      variant="outlined-secondary"
      prepend={!isMobile && <Icon icon={icon} width={20} height={20} />}
      sx={styles}
      onClick={handleOnClick}
    >
      {isMobile ? <Icon icon={icon} width={16} height={16} /> : text}
    </Button>
  );
};

const Header = () => {
  const router = useRouter();
  const route = usePathname();

  const isMiddleScreen = useMediaQuery(theme.breakpoints.up('md'));

  const isHomePage = route === '/';

  const { isAuth, removeUser, setAuth, setUser, user } = useUserStore(
    state => state,
  );

  const { setNotify } = useNotifyStore(state => state);

  const { queries, setQueries } = useQueryParams<{ email: string }>({
    email: '',
  });

  const [otpCode, setOtpCode] = useState<string>('');
  const [fixedHeader, setFixedHeader] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthType>('signIn');

  const open = Boolean(anchorEl);

  const handleCloseModal = () => {
    setOpenModal(false);
    setQueries({ email: '' });
    setAuthType('signIn');
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isAuth) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setOpenModal(true);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await AuthDataApi.logout();

      httpClient.removeXAccessToken();
      httpClient.removeXAcessResource();

      removeUser();
      setAuth(false);

      if (window.location.pathname.includes('/profile')) {
        router.push('/');
      }

      handleClosePopup();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitAuthForm: onSubmitAuthFormType<
    GeneralForm,
    Promise<void>
  > = async (form, trigger) => {
    setFormLoading(true);

    try {
      switch (authType) {
        case 'signUp':
          await handleCreateAccount(form);
          break;
        case 'signIn':
          await handleSignIn(form);
          break;
        case 'resetPassword':
          await handleResetPassword(form);
          break;
        case 'setNewPassword':
          await handleSetNewPassword({
            ...form,
            email: queries.email,
            otp_code: otpCode,
          });
          break;
        case 'signUpConfirmation':
          if (trigger === 'resend') {
            await handleResendOtpCode({
              action: 'profile_activation',
              email: queries.email,
            });
            break;
          }

          await handleConfirmOtpCode({ ...form, email: queries.email });

          setAuthType('signIn');
          break;
        case 'resetConfirmation':
          if (trigger === 'resend') {
            await handleResetPassword(form);
            break;
          }

          setOtpCode(form.otp_code);

          await handleConfirmOtpCode({
            ...form,
            email: queries.email,
            reset_check: true,
          });

          setAuthType('setNewPassword');
          break;
        case 'EmailAlreadyRegistrated':
          await handleResendOtpCode({
            action: 'profile_activation',
            email: queries.email,
          });

          setAuthType('signUpConfirmation');
          break;
        case 'ActiveYourAccount':
          await handleResendOtpCode({
            action: 'profile_activation',
            email: queries.email,
          });

          setAuthType('signUpConfirmation');
          break;
        case 'emailNotFound':
          break;
        default:
          break;
      }
    } catch (error) {
      if (authType === 'signIn') {
        setNotify({
          type: 'error',
          message: 'Invalid email or password',
        });

        return;
      }

      checkErrorMessageHelperSnippet(error as Error, setNotify);

      if (error instanceof Error) {
        const errorData = JSON.parse(error.message);

        if (errorData.detail.non_field_error === 'Email already registered.') {
          setQueries({ email: form.email });
          setAuthType('EmailAlreadyRegistrated');
        }

        if (errorData.detail.non_field_error === 'User does not exist.') {
          setAuthType('emailNotFound');
          setQueries({ email: form.email });
        }

        if (
          errorData.detail.non_field_error === 'Please activate your account.'
        ) {
          setQueries({ email: form.email });
          setAuthType('ActiveYourAccount');
        }
      }
    } finally {
      setFormLoading(false);
    }
  };

  async function handleCreateAccount(form: GeneralForm): Promise<void> {
    await AuthDataApi.signUp(form as SignUpForm);

    setQueries({ email: form.email });

    setAuthType('signUpConfirmation');
  }

  async function handleSignIn(form: GeneralForm): Promise<void> {
    const { data } = await AuthDataApi.signIn(form as SignInForm);

    httpClient.setXAccessToken(data.access_token);
    httpClient.setXAcessResource(data['x-access-resource']);

    setUser({
      ...data,
    });

    setAuth(true);

    setNotify({ type: 'success', message: 'You have successfully logged in' });

    handleCloseModal();
  }

  async function handleResendOtpCode(form: ResesOtpCodeForm): Promise<void> {
    await AuthDataApi.resetOtpCode(form);

    setNotify({ type: 'success', message: 'Otp code has been sent' });
  }

  async function handleResetPassword(form: GeneralForm): Promise<void> {
    await AuthDataApi.resetPassword(form as ResetPasswordForm);

    setAuthType('resetConfirmation');

    setQueries({ email: form.email });
  }

  async function handleSetNewPassword(
    form: CreateNewPasswordForm,
  ): Promise<void> {
    const { data } = await AuthDataApi.createNewPassword(form);

    setAuthType('signIn');

    setNotify({ type: 'success', message: data.message });
  }

  async function handleConfirmOtpCode(form: GeneralForm): Promise<void> {
    await AuthDataApi.activateAccount(form as ConfirmForm);
  }

  const onScrollDocument = () => {
    if (window.scrollY > 100) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window?.addEventListener('scroll', onScrollDocument);
  }, []);

  return (
    <Box
      component="header"
      boxSizing="border-box"
      position={fixedHeader ? 'fixed' : 'absolute'}
      bgcolor={fixedHeader ? colors.white : 'transparent'}
      boxShadow={fixedHeader ? '0px 4px 4px rgba(0, 0, 0, 0.05)' : 'none'}
      padding={{
        xs: `20px 24px ${fixedHeader ? '20px' : '0'}`,
        sm: `20px 7% ${fixedHeader ? '20px' : '0'}`,
        md: `20px 7% ${fixedHeader ? '20px' : '0'}`,
        lg: `20px 8% ${fixedHeader ? '20px' : '0'}`,
        xl: `20px 8% ${fixedHeader ? '20px' : '0'}`,
      }}
      sx={{
        width: '100%',
        zIndex: 100,
        top: 0,
      }}
    >
      <Container disableGutters>
        <Box display="flex" alignItems="center">
          <Box>
            <Logo />
          </Box>

          {isMiddleScreen && !isHomePage && <SearchBar variant="header" />}

          <Box
            component="nav"
            display="flex"
            columnGap={1}
            marginLeft={{
              xs: 'auto',
            }}
          >
            <Tooltip title="Library">
              <Link
                href="/library?type=resources&pageCount=12"
                style={{ display: 'block', textDecoration: 'none' }}
              >
                <HeaderButtons
                  icon="assets"
                  text="Library"
                  sx={{
                    width: {
                      xs: '36px',
                      sm: 'auto',
                    },
                    paddingX: {
                      xs: '10px',
                      sm: '14px',
                      xl: '16px',
                    },
                  }}
                />
              </Link>
            </Tooltip>
            <Tooltip title="Technologies">
              <Link
                href="/technologies"
                style={{ display: 'block', textDecoration: 'none' }}
              >
                <HeaderButtons
                  icon="categories"
                  text="Technologies"
                  sx={{
                    width: {
                      xs: '36px',
                      sm: 'auto',
                    },
                    paddingX: {
                      xs: '10px',
                      sm: '14px',
                      xl: '16px',
                    },
                  }}
                />
              </Link>
            </Tooltip>

            <Tooltip title="For Business">
              <Box>
                <HeaderButtons
                  icon="business"
                  text="For Business"
                  sx={{
                    flex: '1 0 auto',
                    width: {
                      xs: '36px',
                      sm: '146px',
                      xl: '174px',
                    },
                    paddingX: {
                      xs: '10px',
                      sm: '14px',
                      xl: '16px',
                    },
                  }}
                />
              </Box>
            </Tooltip>

            <Tooltip title={isAuth ? 'Profile' : 'Login'}>
              <Box>
                <Button
                  variant="filled"
                  sx={{
                    ...buttonsStyles,
                    flex: '1 0 auto',
                    padding: '0px',
                    width: {
                      xs: '36px',
                      sm: '48px',
                      xl: '60px',
                    },
                  }}
                  onClick={handleOpenModal}
                >
                  {isAuth ? (
                    `${user?.first_name[0]} ${user?.last_name[0]}`
                  ) : (
                    <Icon
                      icon="user"
                      width={{ xs: 16, sm: 24 }}
                      height={{ xs: 16, sm: 24 }}
                      color={colors.white}
                    />
                  )}
                </Button>
              </Box>
            </Tooltip>

            {isAuth && (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClosePopup}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Link href="/profile/settings">
                  <MenuItem onClick={handleClosePopup}>Profile</MenuItem>
                </Link>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            )}
          </Box>
        </Box>
      </Container>

      <GeneralModal
        open={openModal}
        handleClose={handleCloseModal}
        sx={{ backgroundColor: '#F7F9FF' }}
      >
        <Auth
          loading={formLoading}
          setAuthType={setAuthType}
          authType={authType}
          email={queries.email}
          handleClose={handleCloseModal}
          onSubmit={onSubmitAuthForm}
        />
      </GeneralModal>
    </Box>
  );
};

export default Header;
